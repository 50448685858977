import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import Loader from '../../assemblies/loader/loader';
import EventDetails from '../../assemblies/EventDetails/EventDetails';
import GridDetails from '../../assemblies/GridDetails/GridDetails';
import Form from '../../assemblies/form/form';
import PointsBreakdown from '../../assemblies/PointsBreakdown/PointsBreakdown';

import Submission from '../../assemblies/submission/submission';
import Responses from '../../assemblies/responses/responses';

import TabGroup from '../../components/Tabs/TabGroup';
import Tab from '../../components/Tabs/Tab';

import './event.css';

function Event({user}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState({show: false, text: ''});


  const [event, setEvent] = useState({id: searchParams.get('event'), datetime: new Date()});
  const [schedule, setSchedule] = useState([]);
  const [circuit, setCircuit] = useState({});
  // const [form, setForm] = useState({id: null, state: null});

  
  const [activeTab, setActiveTab] = useState('Schedule');
  const handleTabChange = (newTab) => {
      setActiveTab(newTab);
      
      // Add the new query param value to the queryString
      searchParams.set("tab", newTab);
    
      // Enqueue navigation action that updates the queryString
      setSearchParams(searchParams);
  }
  

  useEffect(() => {
    fetchEventData();
    
    if(searchParams.get('tab') !== undefined) {
      let validTabs = [
        'Schedule',
        'Stats',
        'Picks',
        'Results',
        'Points'
      ];

      if(validTabs.includes(searchParams.get('tab'))) {
        setActiveTab(searchParams.get('tab'));
      }
    }
  }, [user]);


  
  const fetchEventData = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId: searchParams.get('event'),
        userId: user.id
      })
    };

    setLoader({show: true, text: 'Loading Event...'});
    fetch(apiUrl + `/app/event`, requestOptions)
      .then(response => response.json())
      .then(data => {
          data.event.datetime = new Date(data.event.date);
          
          data.schedule.forEach(d => d.datetime = new Date(d.date))
          setEvent(data.event);
          setCircuit(data.circuit);
          setSchedule(data.schedule.sort((a, b) => b.datetime - a.datetime));

          setLoader({show: false});
      })
      .catch((err) => {
          console.log(err);
          setLoader({show: false});
      });
  }



  return ( 
    <div className="page-container">
      <Loader config={loader}></Loader>


      <div className="flex justify-content-center">
        <h2 className='single-line'>{event.name}</h2>
      </div>


      <TabGroup>
          <Tab label='Schedule' activeTab={activeTab} onClick={handleTabChange} />
          <Tab label='Stats' activeTab={activeTab} onClick={handleTabChange} />
          {/* {user.isAdmin && (<Tab label='⚙' activeTab={activeTab} onClick={handleTabChange} />)} */}
          {user.isAdmin && (<Tab label='Form' activeTab={activeTab} onClick={handleTabChange} />)}
          {user.id !== null && (<Tab label='Picks' activeTab={activeTab} onClick={handleTabChange} />)}
          {user.id !== null && (<Tab label='Results' activeTab={activeTab} onClick={handleTabChange} disabled={false} />)}
          {user.id !== null && (<Tab label='Points' activeTab={activeTab} onClick={handleTabChange} disabled={false} />)}
      </TabGroup>

      <div className='tab-outlet'>
          {activeTab === "Schedule" && (
            <EventDetails user={user} event={event} fetchEventData={fetchEventData} circuit={circuit} schedule={schedule}></EventDetails>
          )}
          
          {activeTab === "Stats" && (
            <GridDetails user={user} event={event} schedule={schedule}></GridDetails>
          )}
          
          {activeTab === "Form" && (
            <Form user={user} event={event}></Form>
          )}
          
          {activeTab === "Picks" && (
            <Submission user={user} event={event} tabChange={handleTabChange}></Submission>
          )}
          
          {activeTab === "Results" && (
            <Responses user={user} event={event}></Responses>
          )}  
          
          {activeTab === "Points" && (
            <div className="dark-card">
              <PointsBreakdown user={user} event={event}></PointsBreakdown>
            </div>
          )}          
          
          {/* {activeTab === "⚙" && (
            <EditGrid user={user} event={event}></EditGrid>
          )} */}
        </div>
    </div>
  );
}

export default Event;