import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import './Tabs.css';

// Usage: 
// <TabGroup>
//   <Tab>One</Tab>
//   <Tab>Two</Tab>
//   <Tab>Three</Tab>
// </TabGroup>


function Tab({className, onClick, activeTab, disabled, ...attributes}) {
    const isDisabled = !(disabled===undefined || disabled===false);

    const handleOnClick = (e, label) => {
        if(!isDisabled) {
            onClick(label);
        }
    }

    return (
        <div className={`tab ${activeTab === attributes.label ? "active" : ""} ${isDisabled ? "disabled" : ""} ${className!==undefined ? className : ''}`} {...attributes}
        onClick={e => handleOnClick(e, attributes.label)}>
            <div className='label'>{attributes.label}</div>
            {isDisabled && (
                <LockIcon fontSize='small' />
            )}
        </div>
    )
}

export default Tab;