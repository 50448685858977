import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import MenuIcon from '@mui/icons-material/Menu';
import UserChip from '../UserChip/UserChip';
import SignUp from "../../pages/Auth/SignUp";
import SignIn from "../../pages/Auth/SignIn";
import EmailSignUp from "../../pages/Auth/EmailSignUp";
import EmailSignIn from "../../pages/Auth/EmailSignIn";

import './header.css';

function Header({showNav, setShowNav, user}) {
    const navigate = useNavigate();

    const [showSignUp, setShowSignUp] = useState(false);
    const openSignUp = () => setShowSignUp(true);
    const closeSignUp = (action) => {
        setShowSignUp(false);
        if(action==='Open Sign In') {openSignIn();}
        else if(action==='Open Email Sign Up') {openEmailSignUp();}
    }

    const [showEmailSignUp, setShowEmailSignUp] = useState(false);
    const openEmailSignUp = () => setShowEmailSignUp(true);
    const closeEmailSignUp = (action) => {
        setShowEmailSignUp(false);
        if(action==='Open Sign In') {openSignIn();}
        else if(action==='Open Sign Up') {openSignUp();}
    }

    

    const [showSignIn, setShowSignIn] = useState(false);
    const openSignIn = () => setShowSignIn(true);
    const closeSignIn = (action) => {
        setShowSignIn(false);
        if(action==='Open Sign Up') {openSignUp();}
        else if(action==='Open Email Sign In') {openEmailSignIn();}
    }

    const [showEmailSignIn, setShowEmailSignIn] = useState(false);
    const openEmailSignIn = () => setShowEmailSignIn(true);
    const closeEmailSignIn = (action) => {
        setShowEmailSignIn(false);
        if(action==='Open Sign In') {openSignIn();}
        else if(action==='Open Sign Up') {openSignUp();}
    }

    const handleNavBtnClick = () => setShowNav(!showNav);


    const goHome = () => {
        navigate(`/`); 
      }

    return (
        <div className='fixed-header-container'>
            <Modal show={showSignUp} onHide={closeSignUp} closeOnOverlayClick={true}>
                <SignUp onClose={closeSignUp}></SignUp>
            </Modal>

            <Modal show={showEmailSignUp} onHide={closeEmailSignUp} closeOnOverlayClick={true}>
                <EmailSignUp onClose={closeEmailSignUp}></EmailSignUp>
            </Modal>

            <Modal show={showSignIn} onHide={closeSignIn} closeOnOverlayClick={true}>
                <SignIn onClose={closeSignIn}></SignIn>
            </Modal>

            <Modal show={showEmailSignIn} onHide={closeEmailSignIn} closeOnOverlayClick={true}>
                <EmailSignIn onClose={closeEmailSignIn}></EmailSignIn>
            </Modal>

            <div className="header">
                <div className="menu-cluster">
                    <Button variant="icon" onClick={handleNavBtnClick}><MenuIcon /></Button>
                    <div className="cursor-pointer flex-col-center" onClick={goHome}>
                        <img className='logo' src="https://wus1saformulafantasy.blob.core.windows.net/formulafantasy-artifacts/FFLogo.svg" alt="Formula Fantasy" />
                    </div>
                </div>
                <div className="flex">
                    { user.id === null ? (<>
                        <Button variant="link-subtle" onClick={openSignIn}>Sign In</Button>
                        <Button variant="primary" onClick={openSignUp}>Sign Up</Button>
                    </>):(
                        <div className="cursor-pointer" onClick={handleNavBtnClick}>
                            <UserChip variant='avatar' user={user} className='header-chip-avatar'></UserChip>
                            <UserChip variant='header' user={user} className='header-chip'></UserChip>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Header;