import React, { useState, useEffect } from 'react';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import Loader from '../loader/loader';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import './EventSettings.css';

function EventSettings({config, onHide}) {
    // const [formState, setFormState] = useState(formConfig.formState);
    // const [submissionDeadline, setSubmissionDeadline] = useState(formConfig.formSubmissionDeadline);
    const [openF1MeetingData, setOpenF1MeetingData] = useState();
    const [message, setMessage] = useState('');
    const [sessions, setSessions] = useState();
    const [loader, setLoader] = useState({show: false, text: ''});
    
    useEffect(() => {
        setMessage('');

        // setMeetingKey(config.meetingKey);
        // setSessionKey(config.sessionKey);
        if(config.show) {
            fetchEvents();
            console.log('config', config);
        }

    }, [config]);

    

    const fetchEvents = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId
            })
        };

        fetch(apiUrl + "/app/event/sessions", requestOptions)
          .then(response => response.json())
          .then(data => {
            data.forEach(d => d.datetime = new Date(d.date))
            setSessions(data);
            console.log('/app/event/sessions', data);
          })
          .catch((err) => {
            console.log(err);
          });
    }

    const matchOpenF1 = async () => {
        setMessage('');

        let meetingData = await matchOpenF1Meeting();

        if(meetingData === undefined) {
            setMessage('No matching meeting found.');

        } else {
            let importedSessions = await matchOpenF1Sessions(meetingData.meeting_key);
            setMessage(`Found ${importedSessions.length} sessions.`);

            let updatedSessions = compareAndUpdateSessions(importedSessions);

            console.log('meetingData', meetingData);
            console.log('importedSessions', importedSessions);
            console.log('updatedSessions', updatedSessions);

            setOpenF1MeetingData(meetingData);
            setSessions(updatedSessions.sort((a, b) => b.datetime - a.datetime)); 
        }
    }


    const matchOpenF1Meeting = async () => {
        return await fetch(`https://api.openf1.org/v1/meetings?year=${config.season}&meeting_name=${config.name}`)
            .then(response => response.json())
            .then(data => {
                 return data[0]
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
            });
    }

    const matchOpenF1Sessions = async (meetingKey) => {
        return await fetch(`https://api.openf1.org/v1/sessions?meeting_key=${meetingKey}`)
            .then(response => response.json())
            .then(data => {
                data.forEach(d => {
                    d.datetime = new Date(d.date_start);
                })
                return data
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
            });
    }
    
    const compareAndUpdateSessions = (importedSessions) => {
        // Create a copy of current sessions
        let updatedSessions = [...sessions];
      
        // Iterate over imported sessions
        importedSessions.forEach(importedSession => {
            // Find matching event in current events based on type
            let match = updatedSessions.find(session => {
                if(session.type === 'Grand Prix' && importedSession.session_name === 'Race') {return session}
                else if (session.type === 'FP1' && importedSession.session_name === 'Practice 1') {return session}
                else if (session.type === 'FP2' && importedSession.session_name === 'Practice 2') {return session}
                else if (session.type === 'FP3' && importedSession.session_name === 'Practice 3') {return session}
                else if (session.type === importedSession.session_name) {return session}
                else {return undefined}
            });
      
            if (match) {
                // If date or name is different, update the event and set action to "Update"
                if (match.datetime.getTime() === importedSession.datetime.getTime()
                    && match.meetingKey === importedSession.meeting_key
                    && match.sessionKey === importedSession.session_key) {
                    match.action = 'Same';
                } else {
                    match.datetime = importedSession.datetime;
                    match.meetingKey = importedSession.meeting_key;
                    match.sessionKey = importedSession.session_key;
                    match.action = 'Update';
                } 
            } else {
                // If no match is found, add the event to the list with action set to "Add"
                updatedSessions.push({
                    datetime: importedSession.datetime,
                    name: config.name,
                    round: config.round,
                    season: config.season,
                    type: importedSession.session_name,
                    meetingKey: importedSession.meeting_key,
                    sessionKey: importedSession.session_key,
                    action: 'Add'
                });
            }
        });
      
        return updatedSessions;
      };

    const saveMeetingKey = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                meetingKey: openF1MeetingData.meeting_key
            })
        };

        fetch(apiUrl + "/app/event/saveMeetingKey", requestOptions)
          .then(response => response.json())
          .then(data => {
          })
        .catch((err) => {
            console.log(err);
        });
    }

    const updateSession = (e, session) => {
        e.preventDefault();

        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: session.id,
                date: session.datetime,
                meetingKey: session.meetingKey,
                sessionKey: session.sessionKey
            })
        };

        fetch(apiUrl + "/app/event/updateSession", requestOptions)
            .then(response => response.json())
            .then(data => {
            })
        .catch((err) => {
            console.log(err);
        });
    }

    const addSession = (e, session) => {
        e.preventDefault();

        console.log('addSession', session)
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventName: config.name,
                type: session.type,
                date: session.datetime,
                season: config.season,
                locality: config.locality,
                meetingKey: session.meetingKey,
                sessionKey: session.sessionKey
            })
        };

        fetch(apiUrl + "/app/event/addSession", requestOptions)
            .then(response => response.json())
            .then(data => {
            })
        .catch((err) => {
            console.log(err);
        });
    }
    const updateSessionDate = (i, newDate) => {

        let updatedSessions = JSON.parse(JSON.stringify(sessions));
        updatedSessions[i].date = newDate;
        updatedSessions.forEach(s => s.datetime = new Date(s.date))
        setSessions(updatedSessions);
    }

    const handleCloseEvent = () => {
        onHide();
    }
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
                <Loader config={loader}></Loader>
                <h2>Event Settings</h2>
                <div className="label-adorner">
                {`{ eventId: ${config.eventId} }`}
                </div>

                <div className="divider"></div>

                <div className='flex justify-content-between'>
                    <Button variant="outline-light" onClick={matchOpenF1}>Match with OpenF1</Button>
                    <p>Meeting Key: {config.meetingKey}</p>
                    <Button variant="icon" onClick={saveMeetingKey}><SaveIcon fontSize='small' /></Button>
                </div>
                <div>{message}</div>

                <div className="divider"></div>
                <h3>Schedule</h3>
                <table className='table'>
                    <tr>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Session Key</th>
                        <th></th>
                    </tr>
                { sessions !== undefined && sessions.length > 0 && (

                    sessions.map((s, i) => (
                    <tr>
                        <td>{s.type}</td>
                        <td>
                            <DateTimePicker value={dayjs(s.datetime)} onChange={(newValue) => updateSessionDate(i,newValue)} className='datepicker' />
                            {/* <div>{`${s.datetime.toLocaleString([], {weekday: 'long' })}, ${s.datetime.toLocaleString([], {month: 'short', day: 'numeric' })}`}</div>
                            <div>{`${s.datetime.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`}</div> */}
                        </td>
                        <td>{s.sessionKey}</td>
                        <td>
                        {s.action==='Add' && (
                        <Button variant="icon" onClick={(e) => addSession(e, s)}><AddCircleOutlineIcon fontSize='small' /></Button>
                        )}
                        {s.action!=='Add' && (
                        <Button variant="icon" onClick={(e) => updateSession(e, s)}><SaveIcon fontSize='small' /></Button>
                        )}
                        {/* {s.action==='Same' && (
                        <Button variant="icon-light" disabled><SaveIcon fontSize='small' /></Button>
                        )} */}
                        </td>
                    
                    </tr>
                    ))
                )}
                </table>



                <div className="modal-footer">
                    <Button variant="secondary" onClick={handleCloseEvent}>Close</Button>
                    {/* <Button variant="primary" onClick={(e) => submitNewState(e, 'Update')}
                        disabled={formState===formConfig.formState}>Set</Button> */}
                </div>

            </Modal>    
        </LocalizationProvider>

     );
    
}

export default EventSettings;