import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Linkify from 'react-linkify';

import SubmissionConfigModal from '../../assemblies/SubmissionConfigModal/SubmissionConfigModal';

import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';

import Loader from '../loader/loader';
import Driver from '../Driver/Driver';
import Constructor from '../Constructor/Constructor';

import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';




import './submission.css';

function Submission({user, event, tabChange}) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState({show: false, text: ''});
  const [tooltip, setTooltip] = useState({show: false, header: '', body: ''});
  const [reviewModal, setReviewModal] = useState({show: false});
  const [fetchedData, setFetchedData] = useState(false);
  const [submissionConfigModal, setsubmissionConfigModal] = useState({show: false, submissionId: 0, submissionState: 0});
  
  const [submission, setSubmission] = useState({info: {id: null, state: null}, section: [{question: {}}]});


  useEffect(() => {
    console.log('useEffect Submission: ', submission);
    
    if(user.id === null) {
        navigate(`/`);
    } else {
      if(!fetchedData) {
        fetchSubmissionList();
      }
    }
  }, [submission])


  const fetchSubmissionList = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: user.id,
          eventId: event.id
        })
    };

    setLoader({show: true, text: 'Loading Submission...'});
    fetch(apiUrl + `/app/event/submissionList`, requestOptions)
      .then(response => response.json())
      .then(data => {
          setFetchedData(true);

          if(data.length>0) {          
            fetchSubmissionData(data[0]);
          } else {
            createSubmission();
            setLoader({show: false});
          }
  })
      .catch((err) => {
        console.log(err)
        setLoader({show: false});
      });
  }

  const fetchSubmissionData = (submission) => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          submissionId: submission.id
        })
    };

    fetch(apiUrl + `/app/submission`, requestOptions)
      .then(response => response.json())
      .then(data => {
          setFetchedData(true);
          
          for (const [i, s] of Object.entries(data.section)) {
            for (const [j, q] of Object.entries(data.section[i].question)) {
              data.section[i].question[j].expanded = [];

              for (const [k, a] of Object.entries(data.section[i].question[j].answer)) {
                if (Number.isInteger(data.section[i].question[j].answer[k].userAnswer)) {
                  data.section[i].question[j].expanded[data.section[i].question[j].answer.userAnswer] = 'false';
                }
              }
            }
          }


          setSubmission({
            info: submission,
            section: data.section
          });



          setLoader({show: false});
  })
      .catch((err) => {
        console.log(err)
        setLoader({show: false});
      });
  }

 const createSubmission = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId: event.id, 
          userId: user.id 
        })
    };

    setLoader({show: true, text: 'Creating Submission...'});
    fetch(apiUrl + "/app/submission/create", requestOptions)
      .then(response => response.json())
      .then(data => {
        setLoader({show: false});
        fetchSubmissionList();
  })
      .catch((err) => {
        console.log(err);
        setLoader({show: false});
      });
  }


  const handleUserAnswerChange = (event, sectionId, questionId, answerId, order) => {

    // if(form.state==='Complete') return
    if(submission.state==='Submitted') return

    //deep object copy is needed otherwise UseEffect wont fire
    let updatedSubmission;
    try {
      updatedSubmission = structuredClone(submission); 
    } catch {
      console.log('Y structuredClone no work')
    }

    console.log(`handleUserAnswerChange [${event.target.id}] [${sectionId}] [${questionId}] [${answerId}]`);

    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    for (const [i, s] of Object.entries(submission.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(submission.section[i].question)) {
          if (q.id === questionId) {
            for (const [k, a] of Object.entries(submission.section[i].question[j].answer)) {
              if (a.id === answerId) {
                // if (event.target.getAttribute('name').startsWith('User Answer Radio')) {
                  updatedSubmission.section[i].question[j].answer.forEach((el)=>{if(el.userAnswer === order) {el.userAnswer = undefined}});

                  const requestOptions = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({
                        submissionId: submission.info.id, 
                        questionId: questionId,
                        answerId: answerId,
                        order: order,
                        replace: true
                      })
                  };
              
                  // setLoader({show: true, text: 'Saving selection...'});
                  fetch(apiUrl + "/app/submission/answer/set", requestOptions)
                    .then(response => response.json())
                    .then(() => {
                      updatedSubmission.section[i].question[j].answer[k].userAnswer = order;

                      let expandedArray = JSON.parse(JSON.stringify(updatedSubmission.section[i].question[j].expanded));
                      
                      if(expandedArray[order] === 'true') {
                        expandedArray[order] = 'false';
                      } else {
                        expandedArray[order] = 'true';
                      }
          
                      updatedSubmission.section[i].question[j].expanded = expandedArray;
                      
                      setSubmission(updatedSubmission);
                      
                      setLoader({show: false});
                  })
                    .catch((err) => console.log(err));
                // } else {
                //   console.log(`Unknown input name: ${event.target.name} [${a.id}]`);
                // }
              }
            }
          }
        }
      }
    }
  }

  const openSubmissionConfigModal = () => {
    setsubmissionConfigModal({
      show: true, 
      submissionId: submission.info.id, 
      submissionState: submission.info.state
    });
  }
  const closeSubmissionConfigModal = (action) => {
    if (action !== undefined && action === 'Update') {      
      fetchSubmissionList();    
    }
    
    setsubmissionConfigModal({show: false, submissionId: 0, submissionState: 0});
  }

  const [currentOpenAccordian, setCurrentOpenAccordian] = useState(null);

  const openAccordian = (e, a) => {
    e.preventDefault();
    e.stopPropagation();
    
    if(currentOpenAccordian!==a.id) {
      console.log('Open Accordian', a.id);
      setCurrentOpenAccordian(a.id);
    } else {
      setCurrentOpenAccordian(null);
    }
  }
  const isOpenAccordian = (answer) => {
    if(currentOpenAccordian===answer.id) {
      console.log('Open Accordian =>', answer.id);
    }
    return currentOpenAccordian===answer.id;
  }

  const toggleExpandQuestionAnswers = (e, sectionId, questionId, order) => {
    //deep object copy is needed otherwise UseEffect wont fire
    let updatedSubmission;
    try {
      updatedSubmission = structuredClone(submission); 
    } catch {
      console.log('Y structuredClone no work')
    }

    console.log(`toggleExpandQuestionAnswers [${sectionId}] [${questionId}] [${order}]`);

    let expandedArray;

    for (const [i, s] of Object.entries(updatedSubmission.section)) {
      if (s.id === sectionId) {
        for (const [j, q] of Object.entries(updatedSubmission.section[i].question)) {
          if (q.id === questionId) {
            expandedArray = JSON.parse(JSON.stringify(updatedSubmission.section[i].question[j].expanded));
            
            if(expandedArray[order] === 'true') {
              expandedArray[order] = 'false';
            } else {
              expandedArray[order] = 'true';
            }

            updatedSubmission.section[i].question[j].expanded = expandedArray;
          }
        }
      }
    }

    setSubmission(updatedSubmission);
  }

  const showTooltip = (e, header, body) => {
    setTooltip({show: true, header: header, body: body});
  }
  const closeTooltip = () => {
    setTooltip({show: false, header: '', body: ''});
  }


  const showReviewModal = () => {
    setReviewModal({show: true});
  }

  const closeReviewModal = (action) => {  
    if (action !== undefined && action === 'Submit') {
      setLoader({show: true, text: 'Submitting...'});          
  
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;      
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            submissionId: submission.info.id,
            state: 'Submitted'
          })
      };

      
      fetch(apiUrl + `/app/submission/update`, requestOptions)
        .then(response => response.json())
        .then(() => {
          setLoader({show: false});
          setReviewModal({show: false});
          fetchSubmissionList();
          tabChange('Results');
      })
        .catch((err) => console.log(err));
  
    } else {
      setReviewModal({show: false});
    }
  }

  return ( 
    <div className="submission">
      <Loader config={loader}></Loader>
      <SubmissionConfigModal submissionConfig={submissionConfigModal} onHide={closeSubmissionConfigModal}></SubmissionConfigModal>

      <Modal show={tooltip.show} onHide={closeTooltip} center="true" closeOnOverlayClick="true" className="form-config-modal">
          <h2>{tooltip.header}</h2>
          <p>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>
            )}>
              {tooltip.body}
            </Linkify></p>

          <div className="modal-footer">
            <Button variant="secondary" onClick={closeTooltip}>Close</Button>
          </div>
      </Modal>

      <Modal show={reviewModal.show} onHide={closeReviewModal} center="true" closeOnOverlayClick="true">
        
        <h2>Ready to lock your picks?</h2>
        
        
        <p style={{maxWidth: '600px', margin: '1em 0'}}>When you are ready, lock in your picks and see what others have submitted. You do not need to lock in your picks. All picks are locked once the event begins.</p>
        
        <p><b>Picks cannot be unlocked</b>. </p>
{/* 
        <Review submission={submission}></Review> */}

        <div className="modal-footer">
          <Button variant="secondary" onClick={closeReviewModal}>Cancel</Button>
          <Button variant="primary" onClick={() => closeReviewModal('Submit')}>Lock</Button>
        </div>
      </Modal>

      {user.isAdmin && (
      <Button variant='outline-light' onClick={openSubmissionConfigModal}>
        <SettingsIcon fontSize="small" />
        {`{ eId: ${event.id}, subId: ${submission.info.id}, subState: ${submission.info.state} }`}
        <SettingsIcon fontSize="small" />
      </Button>
      )}

      
      <div className='flex justify-content-between align-items-center'>
        {submission.info.state==='Submitted' ? (
          <LockIcon fontSize="small" />
        ):(
          <LockOpenIcon fontSize="small" />
        )}
        
        <Button 
          variant="primary" 
          onClick={showReviewModal}
          disabled={submission.info.state==='Submitted'}>
            Lock Picks <NavigateNextIcon fontSize="small" />
        </Button>
        <Button variant="icon" onClick={(e) => showTooltip(e, 
            'Locking Picks', 
            'When you are ready, lock in your picks and see what others have submitted. You do not need to lock in your picks. All picks are locked once the event begins.'
        )} ><InfoOutlinedIcon fontSize="small" /></Button>
      </div>

      { submission.section.length > 0 && submission.section.map(s => (
          <div className="dark-card section margin-inline-auto scrollable" key={`s${s.id}`} id={s.id}>

            { s.title!==undefined && (
            <div className="section-header">
                <h2>{s.title}</h2>

                {s.showDescription && (
                <Button variant="icon" onClick={(e) => showTooltip(e, s.title, s.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                )}
            </div>
            )}
            
            { s.question.length > 0 && s.question[0].id !== undefined && (
              s.question.map(q => (<>
              <div className='question-header'>
                <div className="flex justify-content-between align-items-center">
                  <h3>{q.text}</h3>
                  <div className='flex'>
                    {q.showDescription && (
                    <Button variant="icon" onClick={(e) => showTooltip(e, q.text, q.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                    )}

                    {q.type !== 'Driver Picks' && (
                    <Button variant='icon' onClick={(e)=>toggleExpandQuestionAnswers(e, s.id, q.id, 0)}>
                      {q.expanded[0] === 'true' ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                    </Button>
                    )}
                  </div>
                </div>
              </div>

              { q.answer.length > 0 && q.answer[0].id !== undefined && (<>

                {q.type === 'Driver Picks' && (<>
                  <div className='flex justify-content-between'>
                    <h3>Group A</h3>
                    <Button variant='icon' onClick={(e)=>toggleExpandQuestionAnswers(e, s.id, q.id, 1)}>
                      {q.expanded[1] === 'true' ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                    </Button>
                  </div>
                  <div className='selection-container'
                    expanded={q.expanded[1]}>
                    {q.answer.filter(a=>a.startPosition<6).map(a => (
                    <div className='selection-item'>
                      <Driver driver={a} variant='input' 
                        expanded={isOpenAccordian(a)} 
                        handleExpansion={openAccordian} 
                        inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                        disabled={submission.state==='submitted'}></Driver>
                    </div>
                    ))}
                  </div>
                  
                  <div className='flex justify-content-between'>
                    <h3>Group B</h3>
                    <Button variant='icon' onClick={(e)=>toggleExpandQuestionAnswers(e, s.id, q.id, 2)}>
                      {q.expanded[2] === 'true' ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                    </Button>
                  </div>
                  <div className='selection-container'
                    expanded={q.expanded[2]}>
                    {q.answer.filter(a=>a.startPosition>5 && a.startPosition<11).map(a => (
                    <div className='selection-item'>
                      <Driver driver={a} variant='input' 
                        expanded={isOpenAccordian(a)} 
                        handleExpansion={openAccordian} 
                        inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                        disabled={submission.state==='submitted'}></Driver>
                    </div>
                    ))}
                  </div>
                  
                  <div className='flex justify-content-between'>
                    <h3>Group C</h3>
                    <Button variant='icon' onClick={(e)=>toggleExpandQuestionAnswers(e, s.id, q.id, 3)}>
                      {q.expanded[3] === 'true' ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                    </Button>
                  </div>
                  <div className='selection-container'
                    expanded={q.expanded[3]}>
                    {q.answer.filter(a=>a.startPosition>10).map(a => (
                    <div className='selection-item'>
                      <Driver driver={a} variant='input' 
                        expanded={isOpenAccordian(a)} 
                        handleExpansion={openAccordian} 
                        inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                        disabled={submission.state==='submitted'}></Driver>
                    </div>
                    ))}
                  </div>
                </>)}


                {q.type === 'Constructor Picks' && (
                <div className='selection-container'
                  expanded={q.expanded[0]}>
                  {q.answer.map(a => (
                  <div className='selection-item'>
                    <Constructor constructor={a} variant='input' 
                      expanded={isOpenAccordian(a)} 
                      handleExpansion={openAccordian} 
                      inputClick={(e, a, order) => handleUserAnswerChange(e, s.id, q.id, a.id, order)}
                      disabled={submission.state==='submitted'}></Constructor>
                  </div>
                  ))}
                </div>
                )}


                {q.type === 'Bonus Radio' && (
                <div className='selection-container'
                  expanded={q.expanded[0]}>
                  {q.answer.map(a => (
                  <div className='selection-item'>
                    <div className={`dark-card selectable gap-0 
                      ${a.imageUrl !== undefined && a.imageUrl !== '' && 'background-image-cover'}
                      ${a.userAnswer!==undefined ? 'selected' : ''} 
                      ${submission.state==='submitted' ? 'disabled' : ''}`}
                    
                      style={a.imageUrl !== undefined && a.imageUrl !== '' ? {backgroundImage: `linear-gradient(to bottom, 
                        rgba(0, 0, 0, 0) 30%, 
                        var(--dark-4)), 
                        url(${a.imageUrl})
                        `,
                        flexShrink: 0,
                        justifyContent: 'flex-end'} : {}}
                      
                      onClick={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 0)} >

                      <div className='answer'>
                        <div className='input'>
                          <input
                              type="radio"
                              checked={a.userAnswer!==undefined} 
                              onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 0)} 
                              disabled={submission.state==='Submitted'} />
                        </div>
                        
                        <div className='bonus-radio-details'>
                          <div className="image-text">{a.text}</div>

                          {a.description !== undefined && a.description !== '' && (
                          <Button variant="icon" onClick={(e) => showTooltip(e, a.text, a.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>))}
                </div>
                )}
                  
                {q.type === 'Podium Picks' && (<>
                  <div className="podium-picks-question">
                    <div>Select podium drivers:</div>
                    <div className="podium-picks-selections">
                      <div>1<sup>st</sup></div>
                      <div>2<sup>nd</sup></div>
                      <div>3<sup>rd</sup></div>
                    </div>
                  </div>

                  {q.answer.map(a => (
                  <div className="podium-picks-answer">
                    <img className="answer-image" src={a.profilePicUrl} alt="answer-img" />

                    <div className="driver-name">
                      <h4>{`${a.firstName} ${a.lastName}`}</h4>
                      <div className='flex align-items-center' style={{gap: '4px'}}>
                        <img className='constructor-logo-inline' src={a.constructorLogoUrl} alt={a.constructorName}></img>
                        <div>{`${a.constructorName}`}</div>
                      </div>
                    </div>

                    <div className="podium-picks-selections">
                      <input
                        type="radio" 
                        name={`User Answer Radio [${a.id}] 1`}
                        checked={a.userAnswer===1} 
                        onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 1)} 
                        disabled={submission.state==='Submitted'} />
                      <input
                        type="radio" 
                        name={`User Answer Radio [${a.id}] 2`}
                        checked={a.userAnswer===2} 
                        onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 2)} 
                        disabled={submission.state==='Submitted'} />
                      <input
                        type="radio" 
                        name={`User Answer Radio [${a.id}] 3`}
                        checked={a.userAnswer===3} 
                        onChange={(e) => handleUserAnswerChange(e, s.id, q.id, a.id, 3)} 
                        disabled={submission.state==='Submitted'} />
                    </div>
                  </div>
                  ))}
                </>)}

              </>)}
            </>))
            )}
          </div>
      ))}
    </div>
  );
}

export default Submission;