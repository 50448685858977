import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../../components/Button/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import TabGroup from '../../components/Tabs/TabGroup';
import Tab from '../../components/Tabs/Tab';
import emailjs from '@emailjs/browser';
import Loader from '../../assemblies/loader/loader';
import './admin.css';

emailjs.init('process.env.EMAIL_JS_USER_ID');

function Admin({user}) {
    const navigate = useNavigate();
    const [fetchedData, setFetchedData] = useState(false);
    const [importMessage, setImportMessage] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [newUser, setNewUser] = useState({
        firstName: '',
        lastName: '',
        displayName: '',
        email: '',
        avatarUrl: ''
    });
    
    const [loader, setLoader] = useState({show: false, text: ''});
    const [activeTab, setActiveTab] = useState('Events');


    const handleTabChange = (newTab) => {
        console.log(newTab)
        setActiveTab(newTab);
    }

    useEffect(() => {
        if(user.id === null) {
            navigate(`/`);
        } else {
            console.log('useEffect Admin: ', allUsers);
            if(!fetchedData) {fetchUserData()}
        }
    }, [allUsers])

    const fetchUserData = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        setLoader({show: true, text: 'Loading All Users...'});
        fetch(apiUrl + `/user/get/all`)
          .then(response => response.json())
          .then(data => {
              setFetchedData(true);
              setAllUsers(data);
              setLoader({show: false});
      })
          .catch((err) => {
            console.log(err);
            setLoader({show: false});
          });
      }

    const handleNewUserChange = (event) => {
        //deep object copy is needed otherwise UseEffect wont fire
        let updatedNewUser = JSON.parse(JSON.stringify(newUser)); 
        console.log(`handleNewUserChange [${event.target.name}]`);
    
        if (event.target.name === 'First Name') {
            updatedNewUser.firstName = event.target.value;
        } else if (event.target.name === 'Last Name') {
            updatedNewUser.lastName = event.target.value;
        } else if (event.target.name === 'Display Name') {
            updatedNewUser.displayName = event.target.value;
        } else if (event.target.name === 'Email') {
            updatedNewUser.email = event.target.value;
        } else if (event.target.name === 'Avatar Url') {
            updatedNewUser.avatarUrl = event.target.value;
        }
        setNewUser(updatedNewUser);
    }
      
    const handleSubmitNew = () => {        
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({                
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                displayName: newUser.displayName,
                email: newUser.email,
                avatarUrl: newUser.avatarUrl
            })
        };
    
        setLoader({show: true, text: 'Adding User...'});
        fetch(apiUrl + "/user/add", requestOptions)
            .then(response => response.json())
            .then(() => {
                fetchUserData();
                setNewUser({
                    firstName: '',
                    lastName: '',
                    displayName: '',
                    email: '',
                    avatarUrl: ''
                });
                setLoader({show: false});
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
            });
    }

    const handleInviteClick = (u) => {

        const templateParams = {
            user_email: u.email,
        };
    
        emailjs.send('process.env.EMAIL_JS_SERVICE_ID', 'template_tbj1etx', templateParams)
            .then((response) => {
            console.log('Email sent!', response.status, response.text);
            }, (error) => {
            console.error('Failed to send email:', error);
            });
    };


    const handlePullDrivers = () => {        
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        setImportMessage('');
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };
    
        setLoader({show: true, text: 'Importing Drivers...'});
        fetch(apiUrl + "/import/drivers", requestOptions)
            .then(response => response.json())
            .then(() => {
                setImportMessage('Drivers Imported.');
                setLoader({show: false});
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
                setImportMessage('Error.');
            });
    }


    const handlePullDriverStandings = () => {        
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        setImportMessage('');
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };
    
        setLoader({show: true, text: 'Importing Driver Standings...'});
        fetch(apiUrl + "/import/driverStandings", requestOptions)
            .then(response => response.json())
            .then(() => {
                setImportMessage('Driver Standings Imported.');
                setLoader({show: false});
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
                setImportMessage('Error.');
            });
    }


    const handlePullConstructors = () => {        
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        setImportMessage('');
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };
    
        setLoader({show: true, text: 'Importing Constructors...'});
        fetch(apiUrl + "/import/constructors", requestOptions)
            .then(response => response.json())
            .then(() => {
                setImportMessage('Constructors Imported.');
                setLoader({show: false});
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
                setImportMessage('Error.');
            });
    }


    const handlePullConstructorStandings = () => {        
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        setImportMessage('');
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };
    
        setLoader({show: true, text: 'Importing Constructor Standings...'});
        fetch(apiUrl + "/import/constructorStandings", requestOptions)
            .then(response => response.json())
            .then(() => {
                setImportMessage('Constructor Standings Imported.');
                setLoader({show: false});
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
                setImportMessage('Error.');
            });
    }

    return (
        <div className="admin-page">
            <Loader config={loader}></Loader>


            <TabGroup>
                <Tab label='Events' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Circuits' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Drivers' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Ergast Import' activeTab={activeTab} onClick={handleTabChange} />
                <Tab label='Users' activeTab={activeTab} onClick={handleTabChange} />
            </TabGroup>


            <div className='tab-outlet'>

                {activeTab === "Events" && (<>
                </>)}

                {activeTab === "Circuits" && (<>
                </>)}

                {activeTab === "Drivers" && (<>
                </>)}

                
                {activeTab === "Ergast Import" && (<>
                    <h2>Import from Ergast</h2>
                
                    <ul>
                        <li><Button variant="link" onClick={handlePullDrivers}>Import Drivers</Button></li> 
                        <li><Button variant="link" onClick={handlePullDriverStandings}>Import Driver Standings</Button></li> 
                        <li><Button variant="link" onClick={handlePullConstructors}>Import Constructors</Button></li>
                        <li><Button variant="link" onClick={handlePullConstructorStandings}>Import Constructor Standings</Button></li>
                    </ul>
                    <p>{importMessage}</p>
                </>)}
                
                {activeTab === "Users" && (<>                
                    <form className="user-form" onSubmit={e => {e.preventDefault();}}>
                        <table className="user-table">
                            <tr>
                                <th></th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Display Name</th>
                                <th>Email</th>
                                <th>Avatar</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td><Button variant="outlined" onClick={(e) => handleSubmitNew(e)}>Add</Button></td>
                                <td><input type="fname" name="First Name" value={newUser.firstName}
                                    onChange={(e) => handleNewUserChange(e)} /></td>
                                <td><input type="lname" name="Last Name" value={newUser.lastName}
                                    onChange={(e) => handleNewUserChange(e)} /></td>
                                <td><input type="dname" name="Display Name" value={newUser.displayName}
                                    onChange={(e) => handleNewUserChange(e)} /></td>
                                <td><input type="email" name="Email" value={newUser.email}
                                    onChange={(e) => handleNewUserChange(e)} /></td>
                                <td><input type="text" name="Avatar URL" value={newUser.avatarUrl}
                                    onChange={(e) => handleNewUserChange(e)} /></td>
                                <td><Button variant="outlined">Clear</Button></td>                    
                            </tr>
                        </table>
                    </form>

                    <form className="user-form" onSubmit={e => {e.preventDefault();}}>
                        <table className="user-table">
                            <tr>
                                <th></th>
                                <th>Id</th>
                                <th>Avatar</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Display Name</th>
                                <th>Email</th>
                                <th>Admin</th>
                                <th></th>
                                <th></th>
                            </tr>

                            { allUsers.length > 0 && (allUsers.map(u => (          
                            <tr>
                                <td><Button variant="outlined">Update</Button></td>
                                <td>{u.id}</td>
                                <td><img className='avatar' src={u.avatarUrl} alt="?" /></td>
                                <td>{u.firstName}</td>
                                <td>{u.lastName}</td>
                                <td>{u.displayName}</td>
                                <td>{u.email}</td>
                                <td>{u.isAdmin ? 'Yes' : ''}</td>
                                <td><Button variant="outlined">Disable</Button></td>
                                <td><Button variant="outlined" onClick={() => handleInviteClick()}>Invite</Button></td>
                            </tr>
                            )))}
                        </table>
                    </form>
                </>)}
            </div>
        </div>
    )
}

export default Admin;