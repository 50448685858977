import React, { useState, useEffect } from 'react';
import Button from '../../components/Button/Button';
import UserChip from '../../assemblies/UserChip/UserChip';
import Chip from '../../components/Chip/Chip';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './Constructor.css';

const Constructor = ({ constructor, variant, expanded, handleExpansion, inputClick, disabled, responsesClick }) => {

    const [isExpanded, setIsExpanded] = useState(expanded);

    const toggleAccordian = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        
        console.log('Toggle Accordian', !isExpanded);
        setIsExpanded(!isExpanded);
        handleExpansion(e, constructor);
    }
    
    useEffect(() => {
        setIsExpanded(expanded);
    }, [ expanded ]);


  return (
    
    <div variant={variant} 
        className={`dark-card gap-0 constructor
        ${variant==='input' ? (disabled ? 'disabled' : 'selectable') : ''} 
        ${constructor.userAnswer!==undefined ? 'selected' : ''}`}
    
        onClick={variant==='input' ? ((e) => inputClick(e, constructor, 0)) : ()=>{}}>

    <div className="main background-image-contain"
    style={{backgroundImage: `url(${constructor.logoUrl})`}}>

        {variant==='input' && (
        <div className='input'>
            <input
                type="radio" 
                name={`User Answer Radio [${constructor}] 1`}
                checked={constructor.userAnswer!==undefined} 
                onChange={(e) => inputClick(e, constructor, 0)} 
                disabled={disabled} />
        </div>
        )}

        <div className="constructor-details">
        <h3>{`${constructor.name}`}</h3>
        </div>
        
        <div className='stats'>
            <Button variant='icon-outline' onClick={(e) => toggleAccordian(e)}>
                <InsertChartOutlinedIcon fontSize='small' />
            </Button>
            
            {constructor.points !== undefined && (
            <Chip>{constructor.points}</Chip>
            )}
        </div>
    </div>
    
    <div className={`stats-accordian ${isExpanded ? 'open' : ''}`}

    style={constructor.accentColor !== undefined && constructor.accentColor !== '' ? {
        backgroundColor: `${constructor.accentColor}`,
        boxShadow: 'inset rgba(0,0,0,1) 0 0 50px -2px'} : {}}>

        {constructor.lastStats !== null && constructor.lastStats !== undefined && (
        <div className='dark-card'>
            <div className='flex justify-content-between align-items-center'>
                <h4>Previous Race</h4>
                <Chip>{constructor.lastStats.points}</Chip>
            </div>

            <p>
                Pit Stops: {constructor.lastStats.pitStopCount}<br /> 
            </p>

            {constructor.lastStats.fastestPitStopPoints>0 && (
            <div className='flex gap-05'>
                <Chip>Fastest Pit</Chip>
            </div>
            )}
        </div>
        )}

        {constructor.currentStats !== null && constructor.currentStats !== undefined && (
        <div className='dark-card'>
            <div className='flex justify-content-between align-items-center'>
                <h4>This Race</h4>
                <Chip>{constructor.currentStats.points}</Chip>
            </div>
            
            {constructor.currentStats.pitStopCount!==null && (
            <p>
                Pit Stops: {constructor.currentStats.pitStopCount}<br /> 
            </p>
            )}
            
            <div className='grid gap-1'>
                {constructor.driver.map(d => (
                <div className='dark-card'>
                    <div className="driver-answer">
                        <img className="answer-image small" src={d.profilePicUrl} alt="answer-img" />
                        <div>
                            <h4>{d.lastName}</h4>
                            
                            <p>
                                {d.endPosition===null && (<>
                                    <span>Starting: P{d.startPosition}</span>
                                </>)}
                                
                                {d.endPosition!==null && (<>
                                
                                    {d.status===null && (<>
                                        <span>Finished: P{d.endPosition} </span>
                                        {d.changePosition>0 && (
                                            <span style={{color: 'green'}}>(<ArrowDropUpIcon fontSize='small' />{d.changePosition})</span>
                                        )}
                                        {d.changePosition===0 && (
                                            <span>(-)</span>
                                        )}
                                        {d.changePosition<0 && (
                                            <span style={{color: 'red'}}>(<ArrowDropDownIcon fontSize='small' />{Math.abs(d.changePosition)})</span>
                                        )}
                                    </>)}
                                    
                                    {d.status!==null && (
                                    <div className='flex gap-05 align-items-center'>
                                        <div>Finished:</div>
                                        <Chip>{d.status}</Chip>
                                    </div>
                                    )}
                                </>)}
                            </p>
                        </div>
                    </div>
                </div>  
                ))}
            </div>
            
            {constructor.currentStats.fastestPitStopPoints>0 && (
            <div className='flex gap-05'>
                <Chip>Fastest Pit</Chip>
            </div>
            )}
        </div>
        )}
    </div>
    
    {variant==='responses' && (
    <div className="submission-avatars" onClick={(e) => responsesClick(e, constructor.submissions)}>
        {constructor.submissions.map(sub => (
        <UserChip variant='avatar' user={sub.user[0]}></UserChip>
        ))}
    </div>
    )}

    </div>
  );
}

export default Constructor;
