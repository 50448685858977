import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import Modal from '../../components/Modal/Modal';
import {logout} from '../../firebase';
import SignUp from "../../pages/Auth/SignUp";
import SignIn from "../../pages/Auth/SignIn";

import './nav.css';

function Nav({showNav, setShowNav, user}) {
    const navigate = useNavigate();
    const {pathname} = useLocation();

    const [showSignUp, setShowSignUp] = useState(false);
    const openSignUp = () => setShowSignUp(true);
    const closeSignUp = (action) => {
        setShowSignUp(false);
        if(action==='Open Sign In') {openSignIn();}
    }

    const [showSignIn, setShowSignIn] = useState(false);
    const openSignIn = () => setShowSignIn(true);
    const closeSignIn = (action) => {
        setShowSignIn(false);
        if(action==='Open Sign Up') {openSignUp();}
    }



    useEffect(() => {
        // console.log('pathname changed: ',pathname);
        setShowNav(false);
    }, [ pathname, setShowNav ]);


    const handleSignOut = () => {               
        logout();

        navigate(`/`);        
        setShowNav(false); // Close the navigation panel
        console.log("Signed out successfully");
    }


    const navigateTo = (e, path) => {        
        navigate(path);   
    }

    return ( 
        <div className={`nav-backdrop ${showNav ? 'backdrop-show' : ''}`} onClick={() => setShowNav(false)} >
            <Modal show={showSignUp} onHide={closeSignUp} closeOnOverlayClick={true}>
                <SignUp onClose={closeSignUp}></SignUp>
            </Modal>

            <Modal show={showSignIn} onHide={closeSignIn} closeOnOverlayClick={true}>
                <SignIn onClose={closeSignIn}></SignIn>
            </Modal>

            <div className={`nav ${showNav ? 'nav-show' : ''}`} onClick={(e) => e.stopPropagation()}>
                <ul className="nav-links">
                    <li className={pathname === '/' ? 'current-page' : ''} 
                        onClick={(e)=>navigateTo(e,'/')}>Home</li>
                    <li className={pathname === '/about' ? 'current-page' : ''}
                        onClick={(e)=>navigateTo(e,'/about')}>About</li>
                </ul>

                {user.isAuthorized && (<>
                <div className="divider"></div>
                <ul className="nav-links">
                    <li className={pathname === '/leaderboard' ? 'current-page' : ''}
                        onClick={(e)=>navigateTo(e,'/Leaderboard')}>Leaderboard</li>
                    <li className={pathname === '/rules' ? 'current-page' : ''}
                        onClick={(e)=>navigateTo(e,'/rules')}>Rules</li>
                    <li className={pathname === '/profile' ? 'current-page' : ''}
                        onClick={(e)=>navigateTo(e,'/profile')}>Profile</li>
                    {user.isAdmin && (
                    <li className={pathname === '/admin' ? 'current-page' : ''}
                        onClick={(e)=>navigateTo(e,'/admin')}>Admin</li>
                    )}
                </ul>

                <div className="divider"></div>
                <ul className="nav-links">                    
                    <li onClick={handleSignOut}>Sign Out</li>
                </ul>
                </>)}
            </div>
        </div>
    );
}
export default Nav;