import React, { useEffect, useState } from 'react';

import Loader from '../../assemblies/loader/loader';

import SessionSettings from '../../assemblies/SessionSettings/SessionSettings';
import { DropdownButton } from '../../components/DropdownButton/DropdownButton';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import SettingsIcon from '@mui/icons-material/Settings';

// import './GridDetails.css';

function GridDetails({user, event, schedule}) {
    const [loader, setLoader] = useState({show: false, text: ''});
    
    const [sessionList, setSessionList] = useState(['']);
    const [selectedSession, setSelectedSession] = useState('');

    const [sessionSettingsConfig, setSessionSettingsConfig] = useState({show: false, eventId: 0});  

    const [drivers, setDrivers] = useState([]);
    const [eventConstructors, setEventConstructors] = useState([]);
    const [eventPitStops, setEventPitStops] = useState([]);

    useEffect(() => {
      if(selectedSession !== '') {
        fetchEventGridData();
      }
    }, [event, selectedSession]);

    useEffect(() => {
        console.log('schedule', schedule);
        console.log('event', event);
        setSessionList(schedule.map(s => s.type));
        setSelectedSession(schedule[0].type);
    }, [schedule]);

    const fetchEventGridData = () => {
        let session = schedule.find(s => s.type===selectedSession);
        fetchEventDriversData(session);
        fetchEventConstructorsData(session);
        fetchEventPitStopsData(session);
    }

    const fetchEventDriversData = (session) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            eventId: session.id
          })
      };
    
      setLoader({show: true, text: 'Loading Event Drivers...'});
      fetch(apiUrl + `/app/event/sessionDrivers`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setDrivers(data);
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setDrivers([]);
            setLoader({show: false});
        });
    }
    
    
    const fetchEventConstructorsData = (session) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId: session.id
        })
    };
  
      setLoader({show: true, text: 'Loading Event Constructors...'});
      fetch(apiUrl + `/app/eventConstructors`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setEventConstructors(data);
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setEventConstructors([]);
            setLoader({show: false});
        });
    }
  
  
    const fetchEventPitStopsData = (session) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId: session.id
        })
    };
  
      setLoader({show: true, text: 'Loading Event Pit Stops...'});
      fetch(apiUrl + `/app/event/sessionPitStops`, requestOptions)
        .then(response => response.json())
        .then(data => {
            // if(data!==undefined) {
              setEventPitStops(data);
              // console.log(data.json());
            // }
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }
    
    const [addPitStopModal, setAddPitStopModal] = useState({show: false});
    const openAddPitStop = () => setAddPitStopModal({show: true, eventId: event.id});
    const closeAddPitStop = (action) => {
      if (action==='Add') {
  
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;      
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({                
            eventId: addPitStopModal.eventId,
            constructorId: addPitStopModal.constructorId,
            driverId: addPitStopModal.driverId,
            lap: addPitStopModal.lap,
            duration: addPitStopModal.duration,
          })
        }
  
        setLoader({show: true, text: `Adding pit stop...`});
        fetch(apiUrl + `/app/eventPitStops/add`, requestOptions)
          // .then(response => response.json())
          .then(() => {
          //   handleSaveEventDrivers(); //recalcuate grid and refetch
            
            setAddPitStopModal({show: false});
            setLoader({show: false});
        })
          .catch((err) => console.log(err));
  
      } else {
        setAddPitStopModal({show: false});
      }
    }
  
    const handleEventConstructorSelection = (constructorName) => {
      const constructorMap = new Map();
      eventConstructors.forEach((entry) => {
        constructorMap.set(entry.constructor.name, entry.constructor.id);
      });
      
      let updatedAddPitStopModal = addPitStopModal;
  
      updatedAddPitStopModal.constructorName = constructorName;
      updatedAddPitStopModal.constructorId = constructorMap.get(constructorName);
  
      console.log('addPitStopModal)',addPitStopModal);
  
      setAddPitStopModal({...updatedAddPitStopModal}); // use a spread since setState didn't trigger a re-render
    }
  
    const handleEventDriverSelection = (driverName) => {
      const driverMap = new Map();
      drivers.forEach((d) => {
        driverMap.set(d.lastName, d.driverId);
      });
      
      let updatedAddPitStopModal = addPitStopModal;
  
  
  
      updatedAddPitStopModal.driverName = driverName;
      updatedAddPitStopModal.driverId = driverMap.get(driverName);
      console.log(updatedAddPitStopModal);
      console.log(addPitStopModal.constructorId===undefined || addPitStopModal.driverId===undefined || addPitStopModal.lap===undefined || addPitStopModal.duration===undefined);
      setAddPitStopModal({...updatedAddPitStopModal}); // use a spread since setState didn't trigger a re-render
    }
  
    const handleEventPitStopChange = (e) => {
      //deep object copy is needed otherwise UseEffect wont fire
      let updatedAddPitStopModal = addPitStopModal; 
      console.log(`handleEventPitStopChange ${e.target.name}`);
  
      if (e.target.name==='Lap') {
        updatedAddPitStopModal.lap = Number(e.target.value);
        console.log(`Updated Lap`);
      }
      
      if (e.target.name==='Duration') {
        updatedAddPitStopModal.duration = Number(e.target.value);
        console.log(`Updated Duration`);
      }
      
      setAddPitStopModal({...updatedAddPitStopModal});
    }


    const openSessionSettings = () => {
      let session = schedule.find(s => s.type===selectedSession);
      console.log('openSessionSettings', session)
        setSessionSettingsConfig({
            show: true, 
            eventId: session.id,
            sessionKey: session.sessionKey,
            type: session.type,
            name: session.name
        });
    }
    const hideSessionSettings = () => {
        setSessionSettingsConfig({show: false, eventId: 0});
        fetchEventGridData();
    }
    
    const getDriverTime = (bestLapTime, gap) => {
      if(gap===0) {
        return formatDuration(bestLapTime)
      } else {
        return `+${formatDuration(gap)}`
      }
    }

    const formatDuration = (duration) => {        
        let minutes = Math.floor(duration / 60);
        let seconds = (duration % 60).toFixed(3); // Keep the thousandth place

        if (minutes === 0) {
            return `${seconds}`;

        } else {
            let [real, decimal] = seconds.split('.');
            real = real.padStart(2, '0'); // Ensure the real part is two digits
            seconds = `${real}.${decimal}`;
            return `${minutes}:${seconds}`;
        }
    }

    return (
    <div className='event-details'>
        <Loader config={loader}></Loader>

        <SessionSettings config={sessionSettingsConfig} onHide={hideSessionSettings}></SessionSettings>

        <Modal show={addPitStopModal.show} onHide={closeAddPitStop}>
          <h2>Add Pit Stop</h2>
          <form className='flex flex-column gap-1'>
              <div className='flex gap-1'>
                <label>Constructor</label>
                <DropdownButton 
                  options={eventConstructors.map((entry) => entry.constructor.name)}
                  value={addPitStopModal.constructorName}
                  clickFunction={(selection) => handleEventConstructorSelection(selection)}
                >
                </DropdownButton>
              </div>

              <div className='flex gap-1'>
                <label>Driver</label>
                <DropdownButton 
                  options={drivers.map((d) => d.lastName)}
                  value={addPitStopModal.driverName}
                  clickFunction={(selection) => handleEventDriverSelection(selection)} 
                >
                </DropdownButton>
              </div>
              
              <div>
                <label>Lap</label>
                <input type="number" 
                    name="Lap" 
                    value={addPitStopModal.lap}
                    onChange={(e) => handleEventPitStopChange(e)} />
              </div>

              <div>
                <label>Time</label> 
                <input type="number" 
                    name="Duration" 
                    value={addPitStopModal.duration}
                    onChange={(e) => handleEventPitStopChange(e)} />
              </div>
          </form>

          <div className='flex flex-column gap-1'>
            <Button variant='primary' onClick={() => closeAddPitStop('Add')} disabled={addPitStopModal.constructorId===undefined || addPitStopModal.driverId===undefined || addPitStopModal.lap===undefined || addPitStopModal.duration===undefined}>Add</Button>
            <Button variant='outline-light' onClick={() => closeAddPitStop()}>Cancel</Button>
          </div>
        </Modal>

        <DropdownButton 
              options={sessionList}
              value={selectedSession}
              clickFunction={(value) => setSelectedSession(value)}
            >
        </DropdownButton>

        <div className="dark-card">
            <div className='flex justify-content-between'>
                <h2>Drivers</h2>

                {user.isAdmin && (
                <Button variant='icon' onClick={openSessionSettings}>
                    <SettingsIcon fontSize="small" />
                </Button>
                )}

            </div>
            <table className="table table-layout-auto">
              <thead>
                  <tr>
                      {/* <th>+/-</th> */}
                      <th className='text-align-center'>Pos.</th>
                      <th className='text-align-left'></th>
                      <th>Time</th>
                      <th>Laps</th>
                      {selectedSession === 'Grand Prix' && (
                        <th>Points</th>
                      )}
                  </tr>
              </thead>
              {drivers.length > 0 && (
              <tbody>
                  {drivers.map(d => (
                  <tr>
                      <td className='text-align-center vertical-align-middle'>{d.currentPosition}</td>
                      <td>
                          <div className='flex gap-1'>
                          <img className="answer-image small" src={d.profilePicUrl} alt="answer-img" />
                          <div className='flex flex-column'>
                              <div className='text-align-left single-line'>{d.firstName} <br /> <b>{d.lastName}</b></div>
                              {/* <div className='flex align-items-center' style={{gap: '4px'}}>
                                <img className='constructor-logo-inline' src={d.constructorLogoUrl} alt={d.constructorName}></img>
                                <div className='single-line'>{`${d.constructorName}`}</div>
                              </div> */}
                              <div className='flex gap-05'>
                                {d.fastestLap && (<div className='chip'>Fastest Lap</div>)}
                                {d.dotd && (<div className='chip'>DotD</div>)}
                                {d.status!==null && (<div className='chip'>{d.status}</div>)}
                              </div>
                          </div>
                          </div>
                      </td>
                      <td>{getDriverTime(d.bestLapTime, d.gap)}</td>
                      <td>{d.lapsCompleted}</td>
                      {selectedSession === 'Grand Prix' && (
                      <td>{d.points}</td>          
                      )}
                  </tr>
                  ))}
              </tbody>
              )}
          </table>
        </div>


        <div className="dark-card">
        <table className="table">
            <thead>
                <tr>
                    <th className='text-align-left'>Constructor</th>
                    <th>Pit Stops</th>
                    <th>Points</th>
                </tr>
            </thead>
            {eventConstructors.length > 0 && (
            <tbody>
                {eventConstructors.map(c => (
                <tr>
                    <td>
                        <div className='flex gap-1'>
                        <img className="answer-image small" src={c.constructor.logoUrl} alt={c.constructor.name} />
                        <div className='flex flex-column align-items-start gap-05'>
                            <div>{`${c.constructor.name}`}</div>
                            <div>
                            {c.fastestPitStopPoints>0 && (<div className='chip'>Fastest Pit Stop</div>)}
                            </div>
                        </div>
                        </div>
                    </td>

                    <td>{c.pitStopCount}</td>
                    <td>{c.points}</td>
                </tr>
                ))}
            </tbody>
            )}
        </table>
        </div>

        <div className="dark-card">
        {user.isAdmin && (<Button>Import DHL Table</Button>)}

        <table className="table">
        <thead>
            <tr>
                <th className='text-align-left'>Constructor</th>
                <th className='td-4em'>Lap</th>
                <th className='td-4em'>Driver</th>
                <th className='td-4em'>Time</th>
            </tr>
        </thead>
        {eventPitStops.length > 0 && (
        <tbody>
            {eventPitStops.map(row => (
            <tr>
                <td>{row.constructor.name}</td>

                <td className='td-4em'>{row.lap}</td>
                
                <td className='td-4em'>{`${row.driver.lastName}`}</td>
                
                <td className='td-4em'>{`${row.duration}`}</td>
            </tr>
            ))}
        </tbody>
        )}
        </table>
        {user.isAdmin && (<Button onClick={openAddPitStop}>Add Pit Stop</Button>)}
        </div>
    </div>
    );
}

export default GridDetails;